<template>
  <div class="floating-navigation">
    <v-btn
      value="home"
      to="/"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_home')"
    >
      <IconHome />
    </v-btn>

    <v-btn
      value="favorites"
      to="/wishlist"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_wishlist')"
    >
      <IconHeart />
    </v-btn>

    <div class="d-flex" style="width: 48px; flex-direction: column">
      <v-btn
        value="scan"
        nuxt
        variant="text"
        icon
        class="camera_button"
        @click="onScanTabClick"
      >
        <IconScan />
      </v-btn>
      <div class="navigation_label" @click="onScanTabClick">Scan</div>
    </div>

    <v-btn
      class="cart"
      value="cart"
      to="/cart"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_bag')"
    >
      <div>
        <div
          v-show="$store.getters['cart/hasProducts']"
          class="cart__badge"
        ></div>
        <IconCart class="cart__icon" />
      </div>
    </v-btn>

    <v-btn
      value="brands"
      to="/brands"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_brand')"
      ><IconSearch class="search__icon"
    /></v-btn>
  </div>
  <QRCodeReaderDialog
    v-model="cameraOpen"
    information-test="test"
    @detect="onDetectQRCode"
  />
</template>

<script lang="ts">
import IconCart from '~/assets/images/icon/icon-cart.svg?component'
import IconHeart from '~/assets/images/icon/icon-heart-on.svg?component'
import IconHome from '~/assets/images/icon/icon-home.svg?component'
import IconScan from '~/assets/images/icon/icon-scan.svg?component'
import IconSearch from '~/assets/images/icon/icon-search.svg?component'
import { Snackbar } from '~/composables/useSnackbar'

type DataType = {
  showStoreMapPopup: boolean
}

export default defineComponent({
  components: {
    IconHome,
    IconHeart,
    IconCart,
    IconSearch,
    IconScan,
  },
  setup() {
    const { $gtmClickPush } = useNuxtApp()
    const cameraOpen = ref(false)
    const router = useRouter()
    const snackbar = inject(snackbarInjectionKey) as Snackbar
    const onDetectQRCode = (value: string) => {
      try {
        const pathObj = convertPathFromUrl(value)
        router.push(pathObj)
        cameraOpen.value = false

        // @ts-ignore
        $gtmClickPush('catalog_qrcode_scan_success', {
          scanValue: value,
        })
      } catch (e: any) {
        snackbar.showError({
          message: '店内のQRコードを読み込んでください',
          timeout: 3000,
        })
      }
    }
    const onScanTabClick = () => {
      cameraOpen.value = true
    }
    return {
      cameraOpen,
      onScanTabClick,
      onDetectQRCode,
    }
  },
  data(): DataType {
    return {
      showStoreMapPopup: false,
    }
  },
})
</script>

<style lang="sass" scoped>
.floating-navigation
  position: fixed
  width: 350px
  height: 60px
  background-color: $main-w-color
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1), inset -10px 10px 20px #EEEEEE
  border-radius: 40px
  margin: auto
  right: 0
  bottom: 19px
  left: 0
  display: flex
  justify-content: space-between
  padding: 0 40px 6px
  align-items: flex-end


.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) :deep() .v-btn__content
  opacity: 1 !important
  path
    fill: #333
.v-btn--icon.v-btn--active :deep()
  path
    fill: #666666

.cart
  position: relative
  top: 2px

.cart__icon
  width: 19px

.cart__badge
  width: 9px
  height: 9px
  border: solid 1px #fff
  border-radius: 50%
  background-color: #DD807F
  position: absolute
  right: 2px
  top: -3px

.search__icon
  width: 22px
  height: 22px

.v-btn--icon.camera_button :deep()
  path
    fill: #FFFFFF

.camera_button
  background: #666666
  box-shadow: 0 0 0 3px white

.camera_button
  &:hover
    background: #3333332d

.navigation_label
  font-family: $en-font-family
  font-weight: bold
  cursor: pointer
  padding-left:4px
</style>
