<template>
  <div v-if="isDevelopment">
    <slot />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const stage = config.public.STAGE

const isDevelopment = computed(() => stage === 'development')
</script>
