<template>
  <WebCatalogNavigation v-if="mode === Mode.WebCatalog" />
  <AiCatalogNavigation v-else-if="mode === Mode.Chat" />
</template>

<script lang="ts">
import { useStore } from 'vuex'
import AiCatalogNavigation from './navigations/AiCatalogNavigation.vue'
import WebCatalogNavigation from './navigations/WebCatalogNavigation.vue'
import { Mode } from '~/utils/url'

export default defineComponent({
  components: {
    WebCatalogNavigation,
    AiCatalogNavigation,
  },
  data() {
    return {
      Mode,
    }
  },
  computed: {
    mode() {
      const store = useStore()
      return store.getters.mode
    },
  },
})
</script>

<style lang="sass" scoped></style>
