// WebカタログサイトのURLを検出したらパス以降を返却する。
// なお、正しくない文字列が来た場合はエラーをスローします。
export const convertPathFromUrl = (url: string) => {
  try {
    const domain = window.location.origin

    const urlObject = new URL(url)
    if (urlObject.origin !== domain) {
      throw new Error('Invalid Domain')
    }

    // クエリパラメータをオブジェクトに変換
    const path = urlObject.pathname
    const query: Record<string, string> = {}
    urlObject.searchParams.forEach((value, key) => {
      query[key] = value
    })

    // pathが想定したものでない場合はエラーを返す。
    if (!path.startsWith('/brands')) {
      throw new Error('Invalid path')
    }

    return {
      path,
      query,
      // hash: urlObject.hash
    }
  } catch (error) {
    throw new Error('Failed Parse')
  }
}
