<template>
  <div class="floating-navigation">
    <v-btn
      value="chat"
      to="/chat"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_chat')"
    >
      <IconChat />
    </v-btn>

    <div class="d-flex" style="flex-direction: column">
      <v-btn
        value="scan"
        nuxt
        variant="text"
        icon
        class="camera_button"
        @click="onScanTabClick"
      >
        <IconScan />
      </v-btn>
      <div class="text-center navigation_label" @click="onScanTabClick">
        Scan
      </div>
    </div>

    <v-btn
      class="cart"
      value="cart"
      to="/chat/cart"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_chat_bag')"
    >
      <div>
        <div
          v-show="$store.getters['cart/hasProducts']"
          class="cart__badge"
        ></div>
        <IconCart class="cart__icon" />
      </div>
    </v-btn>
    <QRCodeReaderDialog
      v-model="qrCodeReaderStore.isOpen"
      :information-text="$t('aiCatalog.scanQRCode')"
      @detect="onQrCodeDetected"
    />
  </div>
</template>

<script lang="ts">
import IconCart from '~/assets/images/icon/icon-cart.svg?component'
import IconChat from '~/assets/images/icon/icon-chat.svg?component'
import IconScan from '~/assets/images/icon/icon-scan.svg?component'
import { Snackbar } from '~/composables/useSnackbar'

export default defineComponent({
  components: {
    IconChat,
    IconScan,
    IconCart,
  },
  setup() {
    const qrCodeReaderStore = useQrCodeReaderStore()
    const route = useRoute()
    const router = useRouter()
    const snackbar = inject(snackbarInjectionKey) as Snackbar

    return {
      qrCodeReaderStore,
      route,
      router,
      snackbar,
    }
  },
  methods: {
    onScanTabClick() {
      this.$gtmClickPush('footer_chat_scan')
      if (this.route.path !== '/chat') {
        this.$router.push({
          path: '/chat',
          hash: '#camera',
        })
      } else {
        this.qrCodeReaderStore.openDialog()
      }
    },
    shiftToChat() {
      if (this.route.path !== '/chat') {
        this.$router.push('/chat')
      }
    },
    onQrCodeDetected(value: string) {
      const result = this.qrCodeReaderStore.detectBrandIdFromQrCode(value)
      if (result) {
        this.shiftToChat()
        this.qrCodeReaderStore.closeDialog()
        // @ts-ignore
        this.$gtmClickPush('chat_qrcode_scan_success', {
          scanValue: value,
        })
      } else {
        // TODO: 変なQRを読み込んでいたらエラーをQRリーダーに表示
        this.snackbar.showError({
          message: this.$t('Invalid QRCode'),
          timeout: 5000,
        })
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.floating-navigation
  width: 300px
  height: 60px
  background-color: $main-w-color
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1), inset -10px 10px 20px #EEEEEE
  border-radius: 40px
  margin: auto
  right: 0
  bottom: 19px
  left: 0
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 40px 6px
  align-items: flex-end

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) :deep() .v-btn__content
  opacity: 1 !important
  path
    fill: #333
.v-btn--icon.v-btn--active :deep()
  path
    fill: #666666

.v-btn--icon.camera_button :deep()
  path
    fill: #FFFFFF

.cart
  position: relative
  top: 2px

.cart__icon
  width: 19px

.cart__badge
  width: 9px
  height: 9px
  border: solid 1px #fff
  border-radius: 50%
  background-color: #DD807F
  position: absolute
  right: 2px
  top: -3px

.camera_button
  background: #666666
  box-shadow: 0 0 0 3px white

.camera_button
  &:hover
    background: #3333332d

.navigation_label
  font-family: $en-font-family
  font-weight: bold
  cursor: pointer
</style>
