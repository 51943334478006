<template>
  <v-dialog
    :model-value="activate"
    fullscreen
    hide-overlay
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
    content-class="v-dialog-qr-content"
  >
    <template #default>
      <div class="fullscreen">
        <QRCodeReader
          :scan-cool-time="props.scanCoolTime"
          :information-text="props.informationText"
          @detect="handleDetect"
          @error="handleError"
        />
        <v-btn flat class="popup-close-btn" @click="close">
          <IconPopupClose />
        </v-btn>
      </div>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import IconPopupClose from '@/assets/images/icon/icon-popup-close.svg?component'
import { Snackbar } from '~/composables/useSnackbar'

const snackbar = inject(snackbarInjectionKey) as Snackbar
const emits = defineEmits<{
  (e: 'detect', value: string): void
  (e: 'update:model-value', value: boolean): void
}>()

const props = defineProps({
  scanCoolTime: {
    type: Number,
    default: 500,
  },
  activate: {
    type: Boolean,
    default: false,
  },
  closeHandle: {
    type: Function,
    default: null,
  },
  informationText: {
    type: String,
    default: null,
  },
})

const handleDetect = (value: string) => {
  emits('detect', value)
}

const handleError = (value: string) => {
  snackbar.showError({
    message: value,
    timeout: 5000,
  })
}

const close = () => {
  if (props.closeHandle) {
    props.closeHandle()
  } else {
    emits('update:model-value', false)
  }
}
</script>

<style lang="sass">

.v-dialog-qr-content
  margin:0 !important
  height: 100%
  max-height: 100% !important
</style>

<style scoped lang="sass">
.fullscreen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: black
  display: flex
  flex-flow: column nowrap
  justify-content: center

.popup-close-btn
  background-color: transparent
  position: absolute
  top: 0
  right: 0
  margin-top: 10px
</style>
